import lax from 'lax.js'
import smoothscroll from 'smoothscroll-polyfill';
import CookiesEuBanner from 'cookies-eu-banner';

// kick off the polyfill!
smoothscroll.polyfill();


window.onload = function () {
  const preLoad = document.getElementById("preload");
  preLoad.classList.add("hide");
  
  laxStuff();
}

window.addEventListener('resize', laxStuff)

const logo = document.getElementById("logo");
if(logo != null) logo.classList.add("straighten"); 

function laxStuff() {
  lax.init()

  // Add a driver that we use to control our animations
  lax.addDriver('scrollY', function () {
    return window.scrollY
  })

  // Add animation bindings to elements
  lax.addElements('.disappear', {
    scrollY: {
      opacity: [
        [0, "screenHeight/2"],
        [1, 0],
      ]
    }
  })

  lax.addElements('.appear', {
    scrollY: {
      opacity: [
        ["elInY", "elCenterY"],
        [0, 1],
      ]
    }
  })

  lax.addElements('.upInTheAir', {
    scrollY: {
      translateY: [
        [0, "elCenterY"],
        {
          992: [0,0],
          1200: [0,-100],
          1400: [0, -200],
        }
      ]
    }
  })

  lax.addElements('.upInTheAirLight', {
    scrollY: {
      translateY: [
        ["elInY", "elCenterY"],
        {
          992: [0,0],
          1200: [0,-50],
          1400: [0, -100],
        }
      ]
    }
  })

  lax.addElements('.upInTheAirAlt', {
    scrollY: {
      translateY: [
        ["elInY", "elOutY"],
        {
          992: [0,0],
          1200: [0,-50],
          1400: [0, -100],
        }
      ]
    }
  })

  lax.addElements('.appear100vh', {
    scrollY: {
      opacity: [
        [0, "screenHeight"],
        [0, 1]
      ]
    }
  })

  // lax.addElements('#header', {
  //   scrollY: {
  //     opacity: [
  //       ["screenHeight-elHeight*2", "screenHeight"],
  //       [0, 1]
  //     ]
  //   }
  // })

  lax.addElements('.headerAppear', {
    scrollY: {
      opacity: [
        ["screenHeight-elHeight*2", "screenHeight"],
        [0, 1]
      ]
    }   
  })

  lax.addElements('.box-1', {
    scrollY: {
      translateX: [
        ["elInY", "elCenterY"],
        [-400, 0]
      ],
      translateY: [
        ["elInY", "elCenterY"],
        [200, 0]
      ]
    }
  })

  lax.addElements('.box-2', {
    scrollY: {
      translateX: [
        ["elInY", "elCenterY"],
        [400, 0]
      ],
      translateY: [
        ["elInY", "elCenterY"],
        [600, 0]
      ]
    }
  })

  lax.addElements('.box-3', {
    scrollY: {
      translateX: [
        ["elInY", "elCenterY"],
        [200, 0]
      ],
      translateY: [
        ["elInY", "elCenterY"],
        [-200, 0]
      ]
    }
  })

  lax.addElements('.box-4', {
    scrollY: {
      translateX: [
        ["elInY", "elCenterY"],
        [400, 0]
      ],
      translateY: [
        ["elInY", "elCenterY"],
        [0, 0]
      ]
    }
  })
}

//accordion
function initAccordion(accordionElem) {

  function handlePanelClick(event) {
    showPanel(event.currentTarget);
  }

  function showPanel(panelHeader) {

    let isActive,
        panel = panelHeader.parentNode,
        panelBody = panelHeader.nextElementSibling,
        expandedPanel = document.querySelector('.panel.active');

    isActive = (expandedPanel && panel.classList.contains('active')) ? true : false;

    if(expandedPanel) {
      expandedPanel.querySelector('.acc-body').style.height = null;
      expandedPanel.classList.remove('active');
    }

    if(panel && !isActive) {
      panelBody.style.height = panelBody.scrollHeight + 'px';
      panel.classList.add('active');
    }

  }

  let allPanelElements = document.querySelectorAll('.panel');

  for(let i = 0; i < allPanelElements.length; i++) {
    allPanelElements[i].querySelector('.acc-header').addEventListener('click', handlePanelClick);
  }

  showPanel(allPanelElements);

}

initAccordion(document.getElementsByClassName('accordion'));

/* smooth scroll */
document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
      });
  });
});

/* cookie banner */
new CookiesEuBanner(function () {
  // Your code to launch when user accept cookies
}, true);